import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'
import TextField from '@/components/core/TextField'
import { Button } from '@/components/core/Button'
import { useForm } from 'react-hook-form'
import api from '@/api'
import { showToast } from '@/utils/toastify'
import { useRouter } from 'next/router'
import { CatchPromise } from '@/utils/catch-promise'

export default function SetupKeyForm({ openaiService, fetchCompanyDetails }) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    register,
  } = useForm({ mode: 'onChange' })

  const router = useRouter()

  const onSubmit = async (data) => {
    const [err, response] = await CatchPromise(
      api.service.create({
        name: 'OpenAI',
        access_key: data.accessKey,
        email_service_list_id: openaiService.id
      })
    )

    if (response) {
      showToast(
        'success',
        'Connection successful',
        'OpenAI is connected successfully'
      )
      fetchCompanyDetails()
    }
  }

  return (
    <div className="m-auto w-full px-20">
      <div>
        <div className="text-center">
          <ChatBubbleOvalLeftEllipsisIcon
            className="inline h-12 w-12 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <h2 className="mt-2 text-lg font-medium text-gray-900">
            Connect your OpenAI account
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            You can find your API key in your OpenAI account settings here: <a href="https://platform.openai.com/account/api-keys" className='text-indigo-600 underline' target='_blank'>https://platform.openai.com/account/api-keys.</a>
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
          <TextField
            name="accessKey"
            validations={{ required: 'API key is required' }}
            register={register}
            error={errors?.accessKey}
            className="mb-3"
            placeholder="OpenAI API Key"
          />
          <Button
            size="fluid"
            className="cursor-pointer"
            disabled={!isValid}
            type="submit"
            text="Connect"
            loading={isSubmitting}
          />
        </form>
      </div>
    </div>
  )
}
