import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PageLoader from '@/components/core/PageLoader'

import api from '@/api'
import { CatchPromise } from '@/utils/catch-promise'
import { showToast } from '@/utils/toastify'
import SetupKeyForm from '@/components/ai-assistant/SetupKeyForm'
import Chat from '@/components/ai-assistant/Chat'

export default function ChatSlider({ setOpen, open }) {
  const [pageReady, setReady] = useState(false)
  const [companyDetails, setCompanyDetails] = useState(null)
  const [openaiService, setOpenaiServices] = useState(null)

  const fetchCompanyDetails = async () => {
    setReady(false)
    const [err, response] = await CatchPromise(api.companies.details())
    if (response) {
      setCompanyDetails(response.data)
    }
    setReady(true)
  }

  const fetchServiceList = async () => {
    setReady(false)
    const [err, response] = await CatchPromise(api.emailServices.index())
    if (response) {
      setOpenaiServices(response.data.find((item) => item.slug == 'openai'))
    }
    setReady(true)
  }

  useEffect(() => {
    fetchCompanyDetails()
  }, [open])

  useEffect(() => {
    fetchServiceList()
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Your personal AI assistant
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {!pageReady ? (
                        <PageLoader />
                      ) : (
                        <>
                          {companyDetails &&
                          !companyDetails.aiAssistantEnabled ? (
                            <div>
                              <SetupKeyForm openaiService={openaiService} fetchCompanyDetails={fetchCompanyDetails} />
                            </div>
                          ) : (
                            <Chat />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
