import { useEffect, useContext } from 'react'
import { LeftNavigation } from '@/components/layouts/LeftNavigation'

import { useSession } from '@/hooks/useSession'
import { useRouter } from 'next/router'
import { Discuss, Puff } from 'react-loader-spinner'

import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from '@/store/sessionSlice'
// import { TrackerContext } from '@/contexts/tracker-context'
import { usePostHog } from 'posthog-js/react'

import { Avatar } from '@/components/core/Avatar'
import Link from 'next/link'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const OnboardingRouteMap = {
  signed_up: '/company-details',
  plan_selected: '/company-address',
  address_added: '/company-address',
  onboarded: '/get-started',
  under_review: '/get-started',
  rejected: '/get-started',
  blocked: '/get-started',
}

const openChat = function () {
  if (Beacon) {
    Beacon('open')
  }
}

export function DashboardLayout({ children, Header }) {
  const { status, validate, signOut } = useSession()
  const router = useRouter()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.sessions.currentUser)
  const [previousPath, setPreviousPath] = useState(null)
  // const { initTracker, startTracking } = useContext(TrackerContext)
  const posthog = usePostHog()
  useEffect(() => {
    ;(async () => {
      await validate((user) => {
        // initTracker()
        // console.log('Tracking started', user)
        if (process.env.NODE_ENV === 'production' && user?.email) {
          posthog.identify(user?.email)
        }
        // startTracking(user?.email)
        dispatch(setCurrentUser(user))
      })
    })()
    return () => {}
  }, [])

  useEffect(() => {
    onboardingCheck()
    if (Beacon) {
      Beacon('close')
    }
  }, [currentUser])

  function onboardingCheck() {
    if (currentUser) {
      const { company } = currentUser

      const { onboardingStatus } = company

      if (previousPath !== null && previousPath !== router.pathname) {
        console.log('Previous path:', previousPath)
      }

      if (router.query.state != 'succeeded') {
        if (
          onboardingStatus != 'onboarded' &&
          onboardingStatus != 'under_review'
        ) {
          if (
            !['/select-plan', '/company-address'].includes(router.pathname) &&
            router.pathname != OnboardingRouteMap[onboardingStatus]
          ) {
            router.push({
              pathname: OnboardingRouteMap[onboardingStatus],
              query: router.query,
            })
          }
        }
      }
    }
  }

  useEffect(() => {
    onboardingCheck()
    setPreviousPath(router.pathname)
    return () => {}
  }, [router.pathname])

  if (status == 'unauthenticated') {
    router.push('/login')
  }

  if (status == 'authenticating' || !currentUser) {
    return (
      <div className="flex h-full">
        <Puff
          height="40"
          width="40"
          radius={1}
          color="#1a1ba6"
          ariaLabel="puff-loading"
          wrapperClass="comment-wrapper m-auto"
          wrapperStyle={{}}
          visible={true}
        />
      </div>
    )
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div className="h-full">
        <LeftNavigation
          onSideBarClick={setSidebarOpen}
          sidebarOpen={sidebarOpen}
        ></LeftNavigation>
        {/* Main column */}
        <div className="flex h-full flex-col lg:pl-64">
          {/* Search header */}
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:hidden">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">
              <div className="flex flex-1">
                {/* <form className="flex w-full md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      name="search-field"
                      className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </form> */}
              </div>
              <div className="flex items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      {currentUser.avatarUrl ? (
                        <Avatar imageUrl={currentUser.avatarUrl} />
                      ) : (
                        <Avatar email={currentUser.email} />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/settings/account-settings"
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              View profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/settings/account-settings/company"
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              Settings
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/settings/billing"
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              Billing
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/settings/integrations"
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              Integrations
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              onClick={openChat}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              Chat with us
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              onClick={() => signOut('/login')}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              Logout
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className="flex-1">
            {Header ? <Header /> : ''}
            {children}
          </main>
        </div>
      </div>
    </>
  )
}
