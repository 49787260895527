import Gravatar from 'react-gravatar'

export const Avatar = ({ name, email, imageUrl }) => {
  function initials(name) {
    if (!name) return ''

    let arr = name.split(' ')

    if (arr.length == 1) {
      return `${arr[0][0]}${arr[0][1]}`
    }

    return `${arr[0][0] ? arr[0][0] : ''}${arr[1][0] ? arr[1][0] : ''}`
  }

  if (email) {
    return <Gravatar email={email} className="rounded-full" default="wavatar" />
  }

  return (
    <span className="inline-flex h-12 w-12 items-center justify-center rounded-full border-gray-100 shadow">
      {imageUrl ? (
        <img className="h-12 w-12 rounded-full" src={imageUrl} alt={name} />
      ) : (
        <span className="bg-gradient-to-br from-gray-400 to-gray-600 bg-clip-text text-lg font-medium leading-none text-transparent">
          {initials(name)}
        </span>
      )}
    </span>
  )
}
