import { Puff } from 'react-loader-spinner'

export default function() {
  return (
    <div className="flex h-full">
      <Puff
        height="40"
        width="40"
        radius={1}
        color="#1a1ba6"
        ariaLabel="puff-loading"
        wrapperClass="comment-wrapper m-auto"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  )
}
