import Image from "next/image"
import logoChq from '@/images/logos/campaignhq-logo.svg'
import redOrangeLogo from '@/images/logos/red-orange-logo.png'
import { useSelector } from 'react-redux'

export function Logo (props) {
  return (
    <Image src={logoChq} width="140" alt="Logo" unoptimized className="mx-auto" />
  )
}

export function CustomLogo (props) {
  const currentUser = useSelector((state) => state.sessions.currentUser)

  if (currentUser && currentUser.email === 'amit@redorangetechnologies.com') {
    return (
      <Image src={redOrangeLogo} width="150" alt="Logo" unoptimized className={props.className} />
    )
  }

  return (
    <Image src={logoChq} width="100" alt="Logo" unoptimized className={props.className} />
  )
}
