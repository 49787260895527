import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'
import TextField from '@/components/core/TextField'
import { Button } from '@/components/core/Button'
import { useForm } from 'react-hook-form'
import api from '@/api'
import { showToast } from '@/utils/toastify'
import { useRouter } from 'next/router'
import { CatchPromise } from '@/utils/catch-promise'
import { useSelector } from 'react-redux'
import Gravatar from 'react-gravatar'
import chatgptLogo from '@/images/logos/chatgpt.png'
import Image from 'next/image'
import { useState, useEffect } from 'react'
import PageLoader from '@/components/core/PageLoader'

export default function Chat() {
  const [pageReady, setReady] = useState(true)
  const [reply, setReply] = useState(null)
  const currentUser = useSelector((state) => state.sessions.currentUser)
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    register,
    reset,
  } = useForm({ mode: 'onChange' })

  const router = useRouter()

  const onSubmit = async (data) => {
    setReady(false)
    const [err, response] = await CatchPromise(
      api.integrations.chat({
        prompt: data.prompt,
      })
    )

    if (response) {
      setReply(response.data)
      reset()
    }
    setReady(true)
  }

  return (
    <div className="w-full">
      <div className="mb-10 h-[70vh] overflow-y-scroll no-scrollbar">
        {!pageReady ? (
          <PageLoader />
        ) : (
          <>
            {reply ? (
              <div className="flow-root">
                <ul role="list" className="-mb-8">
                  <li key={reply.timestamp}>
                    <div className="relative pb-8">
                      <div className="relative flex items-start space-x-3">
                        <div className="relative">
                          <Gravatar
                            email={currentUser.email}
                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                          />
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <p className="text-sm text-gray-500 leading-none">
                              {currentUser.name}
                            </p>
                          </div>
                          <div className="whitespace-pre-line text-sm text-gray-700">
                            <p>{reply.prompt}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li key={reply.timestamp}>
                    <div className="relative pb-8">
                      <div className="relative flex items-start space-x-3">
                        <div className="relative">
                          <Image
                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                            src={chatgptLogo}
                            alt="ChatGPT logo"
                          />
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <p className="text-sm text-gray-500 leading-none">
                              Replied on {reply.timestamp}
                            </p>
                          </div>
                          <div className="mt-2 whitespace-pre-line text-sm text-gray-700">
                            <p>{reply.reply}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              <div className='text-center text-lg'>Ask me anything!</div>
            )}
          </>
        )}
      </div>
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <Gravatar
            email={currentUser.email}
            className="inline-block h-10 w-10 rounded-full"
          />
        </div>
        <div className="min-w-0 flex-1">
          <form onSubmit={handleSubmit(onSubmit)} className="relative">
            <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label htmlFor="comment" className="sr-only">
                Send a message
              </label>

              <textarea
                rows={3}
                name="prompt"
                id="prompt"
                {...register('prompt', { required: 'API key is required' })}
                className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Send a message..."
                defaultValue={''}
              />

              {/* Spacer element to match the height of the toolbar */}
              <div className="py-2" aria-hidden="true">
                {/* Matches height of button in toolbar (1px border + 36px content height) */}
                <div className="py-px">
                  <div className="h-9" />
                </div>
              </div>
            </div>

            <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
              <div className="flex-shrink-0">
                <Button
                  size="fluid"
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={!isValid}
                  type="submit"
                  text="Submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
